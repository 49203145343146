import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Grid, Input, Button, Message } from "semantic-ui-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faInfo } from "@fortawesome/pro-solid-svg-icons";

import { LeftImage, Content, MessageContent, MessageHeader, MessageBottom, CommunicationNote, CloseButton } from "./index.style";

import "./index.scss";

export default function PopupMessage({
  communication,
  settings,
  type,
  isOpen,
  onClose,
  correctPhone,
  updateCorrectPhone,
  onSendAnswer,
  onGoToSuccessScreen,
  onShowErrorModal,
  hideCloseButton,
  showMaintenanceModal,
}) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const renderContactUsSection = () => {
    return (
      <div className="contact-us-section-box">
        <div className="ContactUsSection" style={{ marginTop: "10px" }}>
          <span>{t("cc_CONTACT US").message || "CONTACT US"}:</span>
          <span style={{ marginLeft: "15px" }}>
            <FontAwesomeIcon icon={faPhone} /> {settings.phone_number}
          </span>
          <span style={{ marginLeft: "15px" }}>
            <FontAwesomeIcon icon={faEnvelope} /> {settings.email}
          </span>
        </div>
        {!hideCloseButton && (
          <div className="close-btn">
            <CloseButton color={settings.color}>
              <Button className="closeButton" basic onClick={() => onClose()}>
                {type === "confirm_phone" ? t("cc_Back").message || "Back" : t("cc_Close").message || "Close"}
              </Button>
            </CloseButton>
          </div>
        )}
      </div>
    );
  };

  const renderPhoneConfirmMessage = () => {
    const handlePhoneNr = value => {
      const re = /^[\d-+///(/)]+$/;

      if (value === "" || re.test(value)) {
        updateCorrectPhone(value);
      }
    };

    const handleConfirm = () => {
      if (isLoading) return false;

      setIsConfirmed(true);
      if (correctPhone) {
        const promise = onSendAnswer();
        if (promise) {
          setIsLoading(true);
          promise
            .then(response => {
              setIsLoading(false);
              onGoToSuccessScreen();
            })
            .catch(error => {
              console.log("handleSendAnswer error", error);
              setIsLoading(false);

              const errorMessage = error?.response?.data?.errors?.shift();

              if (errorMessage?.includes("maintenance_mode")) {
                showMaintenanceModal();
              } else {
                onShowErrorModal(true);
              }
            });
        }
      }
    };

    return (
      <Content>
        <MessageContent color={settings.color}>
          <Grid stackable columns="equal">
            <Grid.Column width={16}>
              <span>{t("cc_Confirm your phone number").message || "Confirm your phone number"}</span>
              <br />
              <Input placeholder={t("cc_Phone").message || "Phone"} fluid value={correctPhone} onChange={(e, { value }) => handlePhoneNr(value)} />
              {isConfirmed && !correctPhone && (
                <Message negative>
                  <p>{t("cc_Phone number can't be empty").message || "Phone number can't be empty"}</p>
                </Message>
              )}
            </Grid.Column>
            <Grid.Column width={16} textAlign="right">
              <div className="bottom">
                <Button onClick={handleConfirm} isLoading={isLoading}>
                  {t("cc_CONFIRM ORDER").message || "CONFIRM ORDER"}
                </Button>
              </div>
            </Grid.Column>
          </Grid>
        </MessageContent>
        <MessageBottom>{renderContactUsSection()}</MessageBottom>
      </Content>
    );
  };

  const renderWelcomeMessage = () => {
    let welcomeMsg = t("cc_Welcome").message || "Welcome";
    if (communication?.customer?.title && communication.customer.surname) {
      welcomeMsg += ` ${communication.customer.title}`;
      if (communication.customer.firstname) welcomeMsg += ` ${communication.customer.firstname}`;

      welcomeMsg += ` ${communication.customer.surname}`;
    }

    return (
      <Content>
        <MessageHeader color={settings.color}>
          <h1>{welcomeMsg},</h1>
        </MessageHeader>
        <MessageContent>{settings.welcome_text}</MessageContent>
        {communication.note && (
          <CommunicationNote color={settings.color}>
            <FontAwesomeIcon icon={faInfo} />
            {communication.note}
          </CommunicationNote>
        )}
        <MessageBottom>{renderContactUsSection()}</MessageBottom>
      </Content>
    );
  };

  const renderClosedMessage = () => {
    return (
      <Content>
        <MessageContent>
          {t("cc_this_communication").message ||
            "This digital report is no longer available and you can no longer make changes. If you have any questions, please contact us using the contact information below:"}
        </MessageContent>
        <MessageBottom>{renderContactUsSection()}</MessageBottom>
      </Content>
    );
  };

  if (type === "closed" && communication.is_receptionist) return null;

  return (
    <>
      <Modal className="popup-modal" size="small" open={isOpen} dimmer closeOnDimmerClick={false}>
        <Modal.Content className="modal-content">
          <Grid>
            <Grid.Column computer={4} only="computer">
              <LeftImage welcomePicture={settings.welcome_picture} />
            </Grid.Column>
            <Grid.Column mobile={16} computer={12}>
              {type === "welcome" && renderWelcomeMessage()}
              {type === "confirm_phone" && renderPhoneConfirmMessage()}
              {type === "closed" && renderClosedMessage()}
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    </>
  );
}
