import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Modal } from "semantic-ui-react";

import { CUSTOMER_COMMUNICATION_STATUSES } from "../../../util/communication_statuses";
import { MainLayout, Responsive } from "../../../layout";
import { getPhoneNumber, getEmail } from "../../../util/common";
import { ANSWERS } from "../../../util/answers";

import {
  SuccessMsg,
  ScheduledServices,
  Header,
  Remarks,
  NavigationButtons,
  Order,
  WorkingItems,
  SideMenu,
  Support,
  TiresOverview,
  ErrorModal,
  NotWorkedOnItems,
} from "../components";

import { PopupMessage, OptionalItems } from "./components";
import { SCREENS } from "./util/screens";

import Service from "./service";

import "./index.scss";

class DiagnoseOverview extends Component {
  constructor(props) {
    super(props);

    const { communication } = this.props;

    const agreements = communication.agreements
      .filter(a => a.visible_customcom)
      .map(a => {
        if (communication.status < CUSTOMER_COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_ANSWERED) a.accepted = false;

        return a;
      });

    const previousRemarks = communication.diagnose_overview_remarks;
    previousRemarks.forEach(r => {
      if (!Array.isArray(r.attachments)) r.attachments = [];

      r.attachments.forEach(a => {
        a.name = a.name.substr(-9);
      });
    });

    const remarks = [];
    if (communication.status < CUSTOMER_COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_ANSWERED && this.props.settings.diagnose_overview_remarks_enabled)
      remarks.push({ title: "", description: "", attachments: [] });

    this.state = {
      defaultScreen: communication.status !== CUSTOMER_COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_ANSWERED ? SCREENS.FIRST_SCREEN : SCREENS.LAST_SCREEN,
      screenBeforeSupport: null,
      readOnly: communication.status >= CUSTOMER_COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_ANSWERED || communication.is_receptionist,
      originalCorrectEmail: communication.correct_email || getEmail(communication),
      correctEmail: communication.correct_email || getEmail(communication),
      originalCorrectPhone: communication.correct_phone || getPhoneNumber(communication),
      correctPhone: communication.correct_phone || getPhoneNumber(communication),
      communication_status: communication.status,
      customerName: "",
      advisedItems: OptionalItems.filterAdvisedItems(communication.results),
      criticalItems: OptionalItems.filterCriticalItems(communication.results),
      workingItems: WorkingItems.filterWorkingsItems(communication.results),
      notWorkedOnItems: NotWorkedOnItems.filterNotWorkedOnItems(communication.results),
      previousRemarks,
      remarks,
      approvedMandatoryAgreements: agreements.length === 0 || !agreements.some(a => !a.optional_customcom && !a.accepted),
      agreements,
      visibleConfirmPhoneMessage: false,
      popupIsOpen: true,
      highlightMandatoryItems: false,
      showAgreementError: false,
      showCustomerNameRequiredError: false,
      showMaintenanceModal: false,
      showErrorModal: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.highlightMandatoryItems !== this.state.highlightMandatoryItems) {
      const items = document.getElementsByClassName("optional-items-row red highlight");
      if (items && items.length > 0) window.scrollTo({ behavior: "smooth", top: document.body.scrollHeight });
    }
  }

  displayMaintenanceModal = () => this.setState({ showMaintenanceModal: true });

  getCurrentScreen = () => {
    let { screen } = this.props;
    if (screen) {
      screen = parseInt(screen);
      if (screen === SCREENS.SUPPORT || (screen >= SCREENS.FIRST_SCREEN && screen <= SCREENS.LAST_SCREEN)) {
        return screen;
      }
    }

    return this.state.defaultScreen;
  };

  handleGoToScreen = currentScreen => {
    if (!currentScreen || currentScreen < SCREENS.FIRST_SCREEN || currentScreen > SCREENS.LAST_SCREEN) currentScreen = this.state.defaultScreen;

    if (currentScreen === SCREENS.LAST_SCREEN) {
      this.props.history.replace("", null);
    }

    this.props.history.push("/" + currentScreen + this.props.location.hash);

    window.scrollTo(0, 0);
  };

  handleGoToNextScreen = () => {
    const { criticalItems, advisedItems, workingItems, notWorkedOnItems, remarks, previousRemarks } = this.state;
    const { communication, settings } = this.props;
    const currentScreen = this.getCurrentScreen();
    let shift = 1;

    if (currentScreen + shift === SCREENS.SCHEDULED_SERVICES && !communication.appointment.interventions.length) shift++;

    if (currentScreen + shift === SCREENS.ADVISED_ITEMS && !advisedItems.length) shift++;

    if (currentScreen === SCREENS.ADVISED_ITEMS && !settings.skip_optional_items && !this.allowedToOrderItems()) return;

    if (currentScreen + shift === SCREENS.CRITICAL_ITEMS && !criticalItems.length) shift++;

    if (currentScreen === SCREENS.CRITICAL_ITEMS && !this.allowedToOrderItems()) return;

    if (currentScreen + shift === SCREENS.OTHER_ITEMS && !workingItems.length && !notWorkedOnItems.length) shift++;

    if (currentScreen + shift === SCREENS.REMARKS && (!settings.diagnose_overview_remarks_enabled || remarks.length + previousRemarks.length < 1)) shift++;

    this.handleGoToScreen(currentScreen + shift);
  };

  handleGoToPreviousScreen = () => {
    const { criticalItems, advisedItems, workingItems, notWorkedOnItems, remarks, previousRemarks } = this.state;
    const { communication, settings } = this.props;
    const currentScreen = this.getCurrentScreen();
    let shift = 1;

    if (currentScreen - shift === SCREENS.REMARKS && (!settings.diagnose_overview_remarks_enabled || remarks.length + previousRemarks.length < 1)) shift++;

    if (currentScreen - shift === SCREENS.OTHER_ITEMS && !workingItems.length && !notWorkedOnItems.length) shift++;

    if (currentScreen - shift === SCREENS.CRITICAL_ITEMS && !criticalItems.length) shift++;

    if (currentScreen - shift === SCREENS.ADVISED_ITEMS && !advisedItems.length) shift++;

    if (currentScreen - shift === SCREENS.SCHEDULED_SERVICES && !communication.appointment.interventions.length) shift++;

    this.handleGoToScreen(currentScreen - shift);
  };

  handleGoToSupportScreen = () => {
    this.setState({ screenBeforeSupport: this.getCurrentScreen() });
    this.props.history.push("/" + SCREENS.SUPPORT + this.props.location.hash);
    window.scrollTo(0, 0);
  };

  handleGoBackFromSupportScreen = () => {
    this.handleGoToScreen(this.state.screenBeforeSupport);
  };

  handleGoToSuccessScreen = () => {
    this.setState({ communication_status: CUSTOMER_COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_ANSWERED, readOnly: true, defaultScreen: SCREENS.LAST_SCREEN });
    this.handleGoToScreen(SCREENS.THANK_YOU);
  };

  updateCorrectPhone = correctPhone => {
    this.setState({ correctPhone });
  };

  addNewRemark = () => {
    let { remarks } = this.state;

    remarks = remarks.filter(r => r.title || r.description || r.attachments.length > 0);
    remarks.push({ title: "", description: "", attachments: [] });

    this.setState({ remarks: [...remarks] });
  };

  updateRemarks = remarks => {
    this.setState({ remarks: [...remarks] });
  };

  updateadvisedItems = advisedItems => {
    this.setState({ advisedItems: [...advisedItems] });
  };

  updateCriticalItems = criticalItems => {
    this.setState({ criticalItems: [...criticalItems] });
  };

  handleAgreed = id => {
    let { agreements } = this.state;
    let agreement = agreements.find(a => a.id === id);
    if (agreement) {
      agreement.accepted = !agreement.accepted;
      this.setState({ agreements: [...agreements], approvedMandatoryAgreements: !agreements.some(a => !a.optional_customcom && !a.accepted) });
    }
  };

  handleCustomerName = customerName => {
    this.setState({ customerName });
  };

  handleShowCustomerNameRequiredError = showCustomerNameRequiredError => {
    this.setState({ showCustomerNameRequiredError });
  };

  allowedToOrderItems = () => {
    if (this.props.communication.is_receptionist) return true;

    const { criticalItems, advisedItems } = this.state;
    let allowed = true;

    const currentScreen = this.getCurrentScreen();
    const criticalItemsUnanswered = criticalItems.some(r => r.status === ANSWERS.NOT_ANSWERED),
      advisedItemsUnanswered = !this.props.settings.skip_optional_items && advisedItems.some(r => r.status === ANSWERS.NOT_ANSWERED);

    if (currentScreen === SCREENS.CRITICAL_ITEMS && criticalItemsUnanswered) allowed = false;
    else if (currentScreen === SCREENS.ADVISED_ITEMS && advisedItemsUnanswered) allowed = false;
    else if (currentScreen === SCREENS.FIRST_SCREEN && (criticalItemsUnanswered || advisedItemsUnanswered)) allowed = false;

    if (allowed) this.setState({ highlightMandatoryItems: false });
    else this.setState({ highlightMandatoryItems: new Date().getTime() });

    return allowed;
  };

  handleSendAnswer = () => {
    let { remarks, originalCorrectPhone, originalCorrectEmail, correctEmail, correctPhone, customerName, criticalItems, advisedItems, agreements } = this.state;

    let filledRemarks = remarks.filter(r => !r.readOnly && (r.title || r.description || r.attachments.length > 0));
    let email = correctEmail === originalCorrectEmail ? "" : correctEmail;
    let phone = correctPhone === originalCorrectPhone ? "" : correctPhone;

    if (this.props.settings.skip_optional_items) {
      advisedItems.forEach(ai => {
        if (ai.status === ANSWERS.NOT_ANSWERED) ai.status = ANSWERS.NOT_OK;

        return ai;
      });
    }

    return Service.sendAnswer({
      key: this.props.routeKey,
      results: [...criticalItems, ...advisedItems],
      remarks: filledRemarks,
      agreement_ids: agreements.filter(a => a.accepted).map(a => a.customer_communication_agreement_id),
      correct_phone: phone,
      correct_email: email,
      customer_name: customerName,
    });
  };

  handleAskForPhoneOrSendAnswer = () => {
    const { criticalItems, advisedItems } = this.state;
    if (criticalItems.some(i => i.status === ANSWERS.CONTACT_ME) || advisedItems.some(i => i.status === ANSWERS.CONTACT_ME)) {
      this.setState({ visibleConfirmPhoneMessage: true, popupIsOpen: true });
      return null;
    } else {
      return this.handleSendAnswer();
    }
  };

  handleOnClosePopup = props => {
    this.setState({ popupIsOpen: false });
    if (props?.goToScreen) this.handleGoToScreen(props.goToScreen);
  };

  renderPopups = () => {
    const currentScreen = this.getCurrentScreen();
    const { correctPhone, visibleConfirmPhoneMessage, communication_status, popupIsOpen, showMaintenanceModal, showErrorModal } = this.state;
    const { communication, settings, routeKey, t } = this.props;

    if (showMaintenanceModal)
      return (
        <Modal size="small" open={true} closeOnDimmerClick={false}>
          <Modal.Content>
            <div style={{ textAlign: "center", fontSize: "18px" }}>
              {t("cc_maintenance_message").message || "Sorry, we're down for scheduled maintenance, please try again later"}
            </div>
          </Modal.Content>
        </Modal>
      );

    if (communication_status < CUSTOMER_COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_ANSWERED) {
      if (showErrorModal)
        return (
          <ErrorModal
            isOpen={true}
            onClose={() => this.handleShowErrorModal(false)}
            message={t("cc_error_please_refresh").message || "Error please refresh"}
            onReload={() => window.location.reload(`/${routeKey}`)}
          />
        );

      if (visibleConfirmPhoneMessage) {
        return (
          <PopupMessage
            isOpen={popupIsOpen}
            onClose={this.handleOnClosePopup}
            communication={communication}
            settings={settings}
            type="confirm_phone"
            correctPhone={correctPhone}
            updateCorrectPhone={this.updateCorrectPhone}
            onSendAnswer={this.handleSendAnswer}
            onGoToSuccessScreen={this.handleGoToSuccessScreen}
            onShowErrorModal={this.handleShowErrorModal}
            showMaintenanceModal={this.displayMaintenanceModal}
          />
        );
      }

      return <PopupMessage isOpen={popupIsOpen} onClose={this.handleOnClosePopup} communication={communication} settings={settings} type="welcome" />;
    } else if (communication_status === CUSTOMER_COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_ANSWERED && currentScreen !== SCREENS.THANK_YOU) {
      return <SuccessMsg isOpen={popupIsOpen} onClose={this.handleOnClosePopup} communication={communication} settings={settings} />;
    } else if (communication_status >= CUSTOMER_COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_CLOSED) {
      return (
        <PopupMessage
          isOpen={popupIsOpen}
          onClose={this.handleOnClosePopup}
          communication={communication}
          settings={settings}
          type="closed"
          hideCloseButton={!communication.results.some(r => r.status > ANSWERS.NOT_ANSWERED)}
        />
      );
    }

    return null;
  };

  renderMainColumn = () => {
    const { communication, settings, routeKey, t } = this.props;
    const {
      remarks,
      criticalItems,
      advisedItems,
      workingItems,
      notWorkedOnItems,
      correctEmail,
      correctPhone,
      readOnly,
      highlightMandatoryItems,
      previousRemarks,
    } = this.state;
    const title = t("cc_diagnose_overview").message || "Diagnose overview";
    const currentScreen = this.getCurrentScreen();

    return (
      <Responsive showMobile={currentScreen !== SCREENS.ORDER} className="mainContent">
        {currentScreen === SCREENS.SUPPORT && <Support settings={settings} />}
        {currentScreen !== SCREENS.SUPPORT && (
          <>
            <Header
              showActionLegend
              title={title}
              communication={communication}
              settings={settings}
              showMobile={currentScreen === SCREENS.INFO}
              correctEmail={correctEmail}
              correctPhone={correctPhone}
              showFooter
            />
            <ScheduledServices collapsing communication={communication} settings={settings} showMobile={currentScreen === SCREENS.SCHEDULED_SERVICES} />

            <OptionalItems
              question_status={OptionalItems.QUESTION_STATUS.CRITICAL}
              highlightMandatoryItems={highlightMandatoryItems}
              readOnly={readOnly}
              settings={settings}
              showMobile={currentScreen === SCREENS.CRITICAL_ITEMS}
              items={criticalItems}
              showNotFixedButton={settings.enable_not_fixed_critical}
              updateItems={this.updateCriticalItems}
            />
            <OptionalItems
              question_status={OptionalItems.QUESTION_STATUS.ADVISED}
              highlightMandatoryItems={highlightMandatoryItems}
              readOnly={readOnly}
              settings={settings}
              showMobile={currentScreen === SCREENS.ADVISED_ITEMS}
              items={advisedItems}
              showNotFixedButton={settings.enable_not_fixed_advised}
              updateItems={this.updateadvisedItems}
            />
            <TiresOverview communication={communication} settings={settings} />
            <WorkingItems workingItems={workingItems} showMobile={currentScreen === SCREENS.OTHER_ITEMS} />
            <NotWorkedOnItems notWorkedOnItems={notWorkedOnItems} showMobile={currentScreen === SCREENS.OTHER_ITEMS} />
            {previousRemarks.length > 0 && <Remarks readOnly={true} showMobile={currentScreen === SCREENS.REMARKS} remarks={previousRemarks} />}
            {settings.diagnose_overview_remarks_enabled && (
              <Remarks
                uploadImages={Service.uploadImages}
                readOnly={readOnly}
                routeKey={routeKey}
                showMobile={currentScreen === SCREENS.REMARKS}
                remarks={remarks}
                addNewRemark={this.addNewRemark}
                updateRemarks={this.updateRemarks}
              />
            )}
            <SuccessMsg
              communication={communication}
              settings={settings}
              isOpen={currentScreen === SCREENS.THANK_YOU}
              onClose={() => this.handleOnClosePopup({ goToScreen: SCREENS.INFO })}
            />
          </>
        )}
      </Responsive>
    );
  };

  handleShowAgreementError = showAgreementError => {
    this.setState({ showAgreementError });
  };

  renderOrderColumn = side => {
    const { layout } = this.props.settings;
    if ((layout === 1 && side === "right") || (layout === 2 && side === "left")) {
      const {
        criticalItems,
        advisedItems,
        remarks,
        previousRemarks,
        readOnly,
        agreements,
        approvedMandatoryAgreements,
        showAgreementError,
        customerName,
        showCustomerNameRequiredError,
      } = this.state;
      const { communication, settings } = this.props;
      const currentScreen = this.getCurrentScreen();

      return (
        <Responsive showMobile={currentScreen === SCREENS.ORDER}>
          <SideMenu
            communication={communication}
            settings={settings}
            supportActive={currentScreen === SCREENS.SUPPORT}
            goToSupportScreen={this.handleGoToSupportScreen}
            goBackFromSupportScreen={this.handleGoBackFromSupportScreen}
          >
            <Order
              readOnly={readOnly}
              settings={settings}
              interventions={communication.appointment.interventions}
              optionalItems={[...criticalItems, ...advisedItems]}
              remarks={previousRemarks.concat(...remarks)}
              agreements={agreements}
              approved={approvedMandatoryAgreements}
              onAgreed={this.handleAgreed}
              allowedToOrder={this.allowedToOrderItems}
              onSendAnswer={this.handleAskForPhoneOrSendAnswer}
              onGoToSuccessScreen={this.handleGoToSuccessScreen}
              showAgreementError={showAgreementError}
              onShowAgreementError={this.handleShowAgreementError}
              onCustomerNameChange={this.handleCustomerName}
              customerName={customerName}
              customerNameVisible={settings.diagnose_overview_name_visible}
              onShowCustomerNameRequiredError={this.handleShowCustomerNameRequiredError}
              showCustomerNameRequiredError={showCustomerNameRequiredError}
              showMaintenanceModal={this.displayMaintenanceModal}
            />
          </SideMenu>
        </Responsive>
      );
    }

    return null;
  };

  handleShowErrorModal = showErrorModal => this.setState({ showErrorModal });

  renderNavigation = () => {
    const { communication_status, approvedMandatoryAgreements, showAgreementError, customerName } = this.state;
    const { settings, t } = this.props;

    return (
      <NavigationButtons
        SCREENS={SCREENS}
        answered={communication_status >= CUSTOMER_COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_ANSWERED}
        answerLabel={t("cc_CONFIRM").message || "CONFIRM"}
        approved={approvedMandatoryAgreements}
        currentScreen={this.getCurrentScreen()}
        onGoToPreviousScreen={this.handleGoToPreviousScreen}
        onGoToNextScreen={this.handleGoToNextScreen}
        allowedToOrder={this.allowedToOrderItems}
        onSendAnswer={this.handleAskForPhoneOrSendAnswer}
        onGoToSuccessScreen={this.handleGoToSuccessScreen}
        showAgreementError={showAgreementError}
        onShowAgreementError={this.handleShowAgreementError}
        customerName={customerName}
        customerNameVisible={settings.diagnose_overview_name_visible}
        onShowCustomerNameRequiredError={this.handleShowCustomerNameRequiredError}
        onShowErrorModal={this.handleShowErrorModal}
      />
    );
  };

  render() {
    const { settings, t } = this.props;

    return (
      <MainLayout
        mobileTitle={t("cc_diagnose_overview").message || "Diagnose overview"}
        color={settings.color}
        popups={this.renderPopups()}
        leftColumn={this.renderOrderColumn("left")}
        mainColumn={this.renderMainColumn()}
        rightColumn={this.renderOrderColumn("right")}
        navigation={this.renderNavigation()}
      />
    );
  }
}

export default withRouter(withTranslation()(DiagnoseOverview));
